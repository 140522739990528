<app-modal-header-common [title]="'Action Central'"> </app-modal-header-common>
<div class="plan-form">
  <form [formGroup]="actionForm">
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>Title</mat-label>
      <input matInput formControlName="title" />
    </mat-form-field>
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>Description</mat-label>
      <textarea matInput formControlName="description" rows="4"> </textarea>
    </mat-form-field>
    <div class="row">
      <div class="col-sm-6">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Status</mat-label>
          <mat-select formControlName="status">
            <mat-option value="Open">Open</mat-option>
            <mat-option value="Closed">Closed</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-sm-6">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Assignee</mat-label>
          <mat-select formControlName="assignee" multiple>
            <ng-container *ngFor="let user of teamList">
              <mat-option
                *ngIf="user?.first_name || user?.last_name"
                [value]="user.id"
                >{{user?.first_name}} {{user?.last_name}}</mat-option
              >
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Category</mat-label>
          <mat-select
            formControlName="action_tracker_type"
            (ngModelChange)="onCategorySelection()"
          >
            <mat-option
              [value]="cat.id"
              *ngFor="let cat of actionTrackerCategory"
              >{{cat.display_name}}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-sm-6">
        <div *ngIf="actionForm.controls.action_tracker_type.value == 1">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Opportunity</mat-label>
            <mat-select formControlName="type_id">
              <mat-option [value]="opt.id" *ngFor="let opt of opportunityList"
                >{{opt.opportunity_name}}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="actionForm.controls.action_tracker_type.value == 2">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Project</mat-label>
            <mat-select formControlName="type_id">
              <mat-option [value]="proj.id" *ngFor="let proj of projectList"
                >{{proj.project_name}}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="actionForm.controls.action_tracker_type.value == 3">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Parade</mat-label>
            <mat-select formControlName="type_id">
              <mat-option [value]="parade.id" *ngFor="let parade of paradeList"
                >{{parade.name}}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="actionForm.controls.action_tracker_type.value == 4">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Curated Insight</mat-label>
            <mat-select formControlName="type_id">
              <mat-option
                [value]="insight.id"
                *ngFor="let insight of curatedInsightList"
                >{{insight.title}}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div style="display: flex; justify-content: end" class="mt-3">
      <button
        (click)="closeModal()"
        mat-stroked-button
        mat-button
        color="primary"
        class="mx-1"
      >
        Close
      </button>
      <button
        mat-flat-button
        mat-button
        color="primary"
        class="bg-primary text-white mx-1"
        (click)="createAction()"
      >
        <span *ngIf="!actionForm.controls.id.value">Create</span>
        <span *ngIf="actionForm.controls.id.value">Update</span>
      </button>
    </div>
  </form>
</div>
