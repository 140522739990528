import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ActionTrackerService } from 'src/app/shared/services/action-tracker.service';
import { AddTrackerComponent } from './add-tracker/add-tracker.component';
import { DetailTrackerComponent } from './detail-tracker/detail-tracker.component';
import { IndexComponent } from './index/index.component';
import { ListTrackerComponent } from './list-tracker/list-tracker.component';

const routes: Routes = [
  {
    path: '',
    component: IndexComponent,
    data: { breadcrumb: 'Action Central' },
    children: [
      {
        path: '',
        data: { breadcrumb:null},
        component: ListTrackerComponent,
      },
      {
        path: 'add-new-tracker', data: { breadcrumb: 'Add Action' },
        component: AddTrackerComponent,
      },
      {
        path: 'detail-tracker/:id',
        data: { breadcrumb: (data: any) => data?.action?.unique_ticket_id },
        resolve: { action: ActionTrackerService },
        component: DetailTrackerComponent,
      },
    ]
  }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ActionTrackerRoutingModule { }
