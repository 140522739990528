import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActionTrackerRoutingModule } from './action-tracker-routing.module';
import { ListTrackerComponent } from './list-tracker/list-tracker.component';
import { AddTrackerComponent } from './add-tracker/add-tracker.component';
import { DetailTrackerComponent } from './detail-tracker/detail-tracker.component';
import { IndexComponent } from './index/index.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MatOptionModule } from '@angular/material/core';
import { MaterialmodulesModule } from 'src/app/shared/materialmodules.module';
import { MatInputModule } from '@angular/material/input';
import { ActionCreateModalDialogComponent } from './list-tracker/action-create-modal/action-create-modal';


@NgModule({
  declarations: [
    ListTrackerComponent,
    AddTrackerComponent,
    DetailTrackerComponent,
    IndexComponent,
    ActionCreateModalDialogComponent
  ],
  imports: [
    CommonModule,
    ActionTrackerRoutingModule,
    FormsModule, ReactiveFormsModule,
    SharedModule,
    MatIconModule,
    MaterialmodulesModule,
    MatButtonModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatInputModule,
    MatMenuModule,
    MatOptionModule

  ],

})
export class ActionTrackerModule { }
