import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';


import { ActionTrackerService } from 'src/app/shared/services/action-tracker.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { map, startWith } from 'rxjs/operators';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { ViewEncapsulation } from '@angular/core';

import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
@Component({
  selector: 'app-detail-tracker',
  templateUrl: './detail-tracker.component.html',
  styleUrls: ['./detail-tracker.component.scss'],
  encapsulation:ViewEncapsulation.Emulated
})
export class DetailTrackerComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  user: any;
  trackerDetailsId: any;
  commentsForm = new FormGroup({});
  actionTrackerForm = new FormGroup({});
  actionTrackerAssigneeForm = new FormGroup({});
  trackerForm = new FormGroup({});
  trackerStatusList: any;
  trackerDetail: any;
  commentsList: any[] = [];
  ColorRandom: any;
  viewAllComments: boolean = false;
  selectedLevel: any;
  selectedId: any;
  filterAssignee: any;
  assignee: any;
  asigneeId: any;
  selectedAssignee: any;
  showEdit: boolean = false;
  vendorDetail: any;
  deleteID:any;
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private fb: FormBuilder, private toastr: ToastrService, private spinner: NgxSpinnerService, private actionTrackerService: ActionTrackerService,public dialog: MatDialog,) { }

  ngOnInit(): void {
    this.BuildForm();
    this.initform();
    this.getIdeaStatus();
    this.getTrackerId();
    this.getAssignee();
    this.getvendorDetail();
    this.assigneeform();
  }

  navigateTo(link: string) {
    this.router.navigate([link])
  }

  initform() {
    this.commentsForm = this.fb.group({
      comment: ["", [Validators.required]]
    });
  }
  assigneeform() {
    this.actionTrackerAssigneeForm = this.fb.group({
      assignee: [""]
    });
  }

  private BuildForm() {
    this.actionTrackerForm = this.fb.group({
      status: [],
    });
  }


  getvendorDetail() {
    this.actionTrackerService.getvendordetails().subscribe((response: any) => {
      this.vendorDetail = response;
    });
  }
  getAssignee() {
    this.actionTrackerService.getAssigneeList().subscribe((response: any) => {
      this.assignee = response['records'].find((i: any) => i.field_name == "opportunity_team").field_values;      
    });
  }

  getIdeaStatus() {
    this.actionTrackerService.getStatusFieldValues().subscribe((res: any) => {
      if (res) {
        this.trackerStatusList = res.filter((item: any) => item.label != 'Closed');
      }
    });
  }



  getTrackerId() {
    this.user = JSON.parse(localStorage.getItem('permission') || '{}');
    this.activatedRoute.params.subscribe(params => {
      this.trackerDetailsId = atob(params.id);
      if (this.trackerDetailsId != '') {
        this.getTrackerDeatilsById(this.trackerDetailsId);
      }
    });
  }

  getTrackerDeatilsById(id: any) {
    this.spinner.show();
    this.actionTrackerService.getActionTrackerDetails(id).subscribe((response: any) => {
      if (response) {
        this.trackerDetail = response;
        this.selectedLevel = response?.status;
        this.actionTrackerForm.get("status")?.setValue(response?.status);
        if (response?.comments) {
          this.commentsList = response?.comments;
          this.selectedLevel = response?.status?.id;
          this.selectedAssignee = response?.assignee?.username;
          this.actionTrackerAssigneeForm.get('assignee')?.patchValue(response?.assignee?.username);
        }
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    })
  }

  deleteToeFile() {
    this.actionTrackerService.deleteCommands(this.deleteID).subscribe((response: any) => {
      if (response) {
        this.spinner.hide();
        this.getTrackerDeatilsById(this.trackerDetailsId);
        this.toastr.success("Comments Deleted Succesfully");
      }
      else {
        this.spinner.hide();
      }
    })

  }

  downloadFile(e: any) {
    var payload = {
      file_name: e,
    }
    this.spinner.show();
    this.actionTrackerService.downloadFile(payload, this.trackerDetailsId).subscribe((res: any) => {
      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(res);
      downloadLink.setAttribute('download', e);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      this.toastr.success('File downloaded successfully');
      this.spinner.hide();
    }, (error: any) => {
      this.spinner.hide();
      throw error;
    })
  }


  SaveComments() {
    if (this.commentsForm.invalid) {
      return;
    }
    this.actionTrackerService.updateComments(this.commentsForm.value, this.trackerDetailsId).subscribe((response: any) => {
      if (response) {
        this.spinner.hide();
        this.getTrackerDeatilsById(this.trackerDetailsId);
        this.commentsForm.reset();
        this.toastr.success("Comments Added Succesfully");
      }
      else {
        this.spinner.hide();
      }
    })
  }

  getName(name: any) {
    return name?.split(' ').slice(0, 2).map((n: any) => n[0]).join('');
  }

  getRandomcolors(index?: any) {
    const colors: any[] = ["#ed5c49", "#f9a914", "#0f355a", "#48db7e", "#479cdd", "#ff9a07", "#6ce197", "#38c4ab", "#df559e", "#f07c6c"];
    this.ColorRandom = Math.floor(Math.random() * colors.length);
    this.ColorRandom = colors[index];
    return this.ColorRandom;

  }


  viewAllComment() {
    this.viewAllComments = true;
  }

  selectTrackerStatus(event: any) {
    this.selectedId = event.value;
    //var status_name = this.trackerStatusList.find((x: any) => x.id === this.selectedId).slug;
    const data = {
      action_tracker_id: this.trackerDetailsId,
      status: this.selectedLevel
    }
    this.actionTrackerService.updateStatus(data).subscribe((response: any) => {
      if (response) {
        this.spinner.hide();
        this.getTrackerDeatilsById(this.trackerDetailsId);
        this.toastr.success("Updated Successfully");
      }
      else {
        this.spinner.hide();
      }
    })
  }

  resolveIssue() {
    const data = {
      action_tracker_id: this.trackerDetailsId,
      status: "action_tracker_resolved"
    }
    this.actionTrackerService.updateStatus(data).subscribe((response: any) => {
      if (response) {
        this.getTrackerDeatilsById(this.trackerDetailsId);
        this.toastr.success("Resolved Successfully");
        this.spinner.hide();
        this.router.navigate(['/actiontracker'])
      }
      else {
        this.spinner.hide();
      }
    })
  }

  updateAssignee(event: any) {
    this.asigneeId = event.value;
    const data = {
      file_name: [],
      assignee: this.asigneeId
    }
    this.actionTrackerService.updateassignee(data, this.trackerDetailsId).subscribe((response: any) => {
      if (response) {
        this.spinner.hide();
        this.getTrackerDeatilsById(this.trackerDetailsId);
        this.toastr.success("Updated Successfully");
      }
      else {
        this.spinner.hide();
      }
    })
  }
  deleteComment(id: any, content: any) {
    this.deleteID = id;
    this.dialog.open(content)
  }
  closeDialog() {
    this.dialog.closeAll();
  }

}
