<div class="container-fluid container-xxl add-idea">
    <div class="row">
        <div class="col-md-9 px-4 center-section  mt-4">
            <div class="row ">
                <div class="col-md-12">
                    <div class="pt-4">
                        <app-bread-crumb></app-bread-crumb>
                        <div class="d-flex justify-content-between align-items-center cursor-pointer">
                            <h2 class="screen-name text-truncate mb-0" (click)="navigateTo('actiontracker')">
                                <i class="far fa-angle-left pr-2"></i> {{trackerDetail?.title}}
                            </h2>

                            <form [formGroup]="actionTrackerForm">
                                <mat-select class="select-box w-100  px-2" placeholder="SELECT"
                                    [ngClass]="{'approved': trackerDetail?.status == 'Open' , 'progress': trackerDetail?.status == 'Inprogress' , 'hold': trackerDetail?.status == 'Hold',
                                'empty-status':  trackerDetail?.status !== 'Under Review' &&  trackerDetail?.status !== 'Open' , 'rejected': trackerDetail?.status == 'Closed'}"
                                    [(ngModel)]="selectedLevel" formControlName="status"
                                    (selectionChange)="selectTrackerStatus($event)">
                                    <!-- <mat-option *ngFor="let data of trackerStatusList" [value]="data?.id">
                                        <span>{{data}}</span>
                                    </mat-option> -->
                                    <mat-option value="Open">Open</mat-option>
                                    <mat-option value="Closed">Closed</mat-option>
                                </mat-select>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <div>
                <div class="row m-0 section-2">
                    <div class="breif-desc">
                        <span class="bubble-top">&nbsp;</span>
                        <label class="mt-4">{{staticText?.action_tracker.brief_desc}}</label>
                        <p>{{trackerDetail?.description}}</p>
                    </div>


                    <div class="assignee-details">
                        <span class="bubble-top">&nbsp;</span>
                        <div class="d-flex justifiy-content-between align-items-center">
                            <div>
                                <label class="mt-4">{{staticText?.action_tracker.assignee}}</label>
                            </div>
                            <div (click)="showEdit=!showEdit">
                                <i class="fas fa-edit mx-5 edit mt-4"><span class="px-1">Edit</span></i>
                            </div>
                        </div>

                        <div class="d-flex align-items-center mt-2" *ngIf="trackerDetail?.assignee.first_name != ''">
                            <span
                                class="name-1 fw-bold mr-2">{{getName(trackerDetail?.assignee.first_name)}}{{getName(trackerDetail?.assignee.last_name)}}</span>
                            <p class="m-0">{{trackerDetail?.assignee.first_name}} {{trackerDetail?.assignee.last_name}}
                            </p>
                        </div>
                        <div class="d-flex align-items-center mt-2" *ngIf="trackerDetail?.assignee.first_name == ''">
                            <span class="name-1 fw-bold mr-2">{{getName(trackerDetail?.assignee.username)}}</span>
                            <p class="m-0">{{trackerDetail?.assignee.username}}
                            </p>
                        </div>

                        <div class="mt-2" *ngIf="showEdit">
                            <form [formGroup]="actionTrackerAssigneeForm">
                                <mat-select class="assignee-selectbox  px-2 " placeholder="SELECT"
                                    [(ngModel)]="selectedAssignee" formControlName="assignee"
                                    (selectionChange)="updateAssignee($event)">
                                    <mat-option *ngFor="let data of assignee" [value]="data.mail">
                                        <span>{{data?.name}}</span>
                                    </mat-option>
                                </mat-select>
                            </form>
                        </div>
                    </div>

                </div>

                <div class="row section-3 mt-2 m-0">
                    <label class="document fw-bold p-0 mb-0">{{staticText?.action_tracker.supporting_docs}}</label>
                    <div class="d-flex p-0">
                        <span class="file d-flex" *ngFor="let data of trackerDetail?.files">
                            <div class="d-flex" *ngIf="data?.file.includes('.doc') || data?.file.includes('.docx') ">
                                <img src="./assets/images/docs.png" class="img-pdf cursor-pointer"
                                    height="40">
                                <p class="w-100 cursor-pointer" style="font-size: 8pt;">
                                    {{data?.file}}</p>
                                <img src="./assets/images/download-icon-new.svg"
                                    class="img-pdf cursor-pointer" height="40" (click)="downloadFile(data?.file)">
                            </div>
                            <div class="d-flex" *ngIf="data?.file.includes('.pdf') ">
                                <img src="./assets/images/pdf-icon-new.svg" height="40"
                                    class="img-pdf cursor-pointer">
                                <p class="w-100 cursor-pointer" style="font-size: 8pt;">
                                    {{data?.file}}</p>
                                <img src="./assets/images/download-icon-new.svg"
                                    class="img-pdf cursor-pointer" height="40" (click)="downloadFile(data?.file)">
                            </div>
                            <div class="d-flex" *ngIf="data?.file.includes('.ppt') || data?.file.includes('.pptx') ">
                                <img src="./assets/images/powerpoint.png" height="40"
                                    class="img-pdf cursor-pointer">
                                <p class="w-100 cursor-pointer" style="font-size: 8pt;">
                                    {{data?.file}}</p>
                                <img src="./assets/images/download-icon-new.svg"
                                    class="img-pdf cursor-pointer" height="40" (click)="downloadFile(data?.file)">
                            </div>
                            <div class="d-flex" *ngIf="data?.file.includes('.xlsx') || data?.file.includes('.xls')|| data?.file.includes('.csv') ">
                                <img src="./assets/images/excel.png" height="40"
                                    class="img-pdf cursor-pointer">
                                <p class="w-100 cursor-pointer" style="font-size: 8pt;">
                                    {{data?.file}}</p>
                                <img src="./assets/images/download-icon-new.svg"
                                    class="img-pdf cursor-pointer" height="40" (click)="downloadFile(data?.file)">
                            </div>
                        </span>
                        <br />
                    </div>

                    <div class="p-0" *ngIf="trackerDetail?.files.length == 0 || trackerDetail?.files.length == ''">
                        <p class="no-doc">{{staticText?.common.no_docs_found}} </p>

                    </div>
                </div>

                <div class="row section-4 m-0">
                    <div class="comments mb-4 p-0">
                        <label class="fw-bold text-dark mb-3">{{staticText?.action_tracker.comments}}</label>
                        <div class="d-flex mr-1">
                            <span
                                class="name-1 fw-bold mr-2">{{getName(user?.first_name)}}{{getName(user?.last_name)}}</span>
                            <form [formGroup]="commentsForm">
                                <div class="form-group position-relative cursor-pointer">
                                    <img src="/assets/images/send.svg" (click)="SaveComments()">
                                    <input type="text" class="form-control comments-field " id="exampleInputEmail1"
                                        formControlName="comment" aria-describedby="emailHelp"
                                        placeholder="Add comments...">
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="col-md-12 align-items-center col p-0"
                        *ngFor="let data of commentsList  | slice:0:!viewAllComments ? 3 : -1;index as commentIndex">
                        <div class=" row comments-list justify-content-between align-items-center"
                            *ngIf="commentsList.length > 0">
                            <div class="col-md-11">
                                <div class="d-flex">
                                    <div class="mr-1">
                                        <ngx-avatar [bgColor]="getRandomcolors(commentIndex)"
                                            [name]="data.created_by.username" size="38" [round]="true">
                                        </ngx-avatar>
                                    </div>
                                    <div>
                                        <div class="ml-2">
                                            <p class="text-dark fw-bold text-truncate card-title cursor-pointer mb-0">
                                                {{data.created_by.first_name}} {{data.created_by.last_name}}
                                            </p>
                                            <small class="text-muted">{{data.last_updated_on | date: 'd MMM y , h:mm
                                                a'}}</small>
                                        </div>
                                        <div class="d-flex mt-2 ml-2">
                                            <p class="comments-style">{{data.comment}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-1" *ngIf="data?.flag"  (click)="deleteComment(data?.id,delete)">
                                <img class="cursor-pointer delete-icon"
                                    src="./assets/images/delete-icon-new.svg"
                                   >
                            </div>
                        </div>
                    </div>

                    <div class="p-1 container-fluid mb-4" *ngIf="commentsList.length > 3 && !viewAllComments">
                        <div class="row row-cols-2">
                            <div class="col-8">

                                <div class="avatarList"
                                    *ngFor="let data of commentsList  | slice:3:20;index as commentIndex"
                                    [ngStyle]="{'transform': 'translate('+ 22 * commentIndex + 'px,-5px)'}">
                                    <ngx-avatar [name]="data?.created_by?.username"
                                        [bgColor]="getRandomcolors(commentIndex)" size="38" [round]="true">
                                    </ngx-avatar>
                                </div>
                            </div>
                            <div class="col-4">
                                <p class="h5 font-15 mb-0 mt-1 text-capitalize cmd cursor-pointer"
                                    (click)="viewAllComment()">
                                    {{commentsList.length - 3 +'+ others'}}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="d-flex justify-content-end">
                    <button mat-button class="my-3 px-4 p-0 border border-default btn-bg" (click)="resolveIssue()">
                        <span class="m-0">
                            {{staticText?.action_tracker.resolve_issue}}
                        </span>
                    </button>

                </div>
            </div>
        </div>
        <div class="col-md-3  mt-4">
            <div class="right-section">
                <span>
                    <div class="row">
                        <div class="my-3 d-flex border-bottom">
                            <div class="bubble-top bg-3  mr-2"></div>
                            <label for="benefits" class="labelstyle">{{staticText?.action_tracker.more_details}}</label>
                            <hr>
                        </div>
                    </div>
                </span>

                <div class="row mx-2">

                    <div class="d-flex align-items-center user-details">
                        <div class="mr-1">
                            <span class="name-1 cursor-pointer">
                                {{getName(trackerDetail?.created_by.first_name)}}
                                {{getName(trackerDetail?.created_by.last_name)}}
                            </span>
                        </div>
                        <div>
                            <div>
                                <p class="details">
                                    {{trackerDetail?.created_by.first_name}} {{trackerDetail?.created_by.last_name}}
                                </p>
                            </div>
                            <small class="text-muted text-nowrap">Creation on {{trackerDetail?.created_on | date: 'd MMM
                                y'}}</small>
                        </div>
                    </div>
                    <div class="more-details">
                        <div class="d-flex align-items-center mb-3" *ngIf="trackerDetail?.vendor_detail == true">
                            <div class="mr-1">
                                <span class="avatar cursor-pointer">
                                    {{getName(trackerDetail?.created_by.first_name)}}
                                    {{getName(trackerDetail?.created_by.last_name)}}
                                </span>
                            </div>
                            <div>
                                <div>
                                    <p class="details">
                                        {{trackerDetail?.created_by.first_name}} {{trackerDetail?.created_by.last_name}}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex align-items-center mb-3" *ngIf="trackerDetail?.vendor_details == false">
                            <div class="mr-1">
                                <span class="avatar cursor-pointer">
                                    {{getName(vendorDetail?.user_details)}}
                                </span>
                            </div>
                            <div>
                                <div>
                                    <p class="details">
                                        Delta {{vendorDetail?.user_details}}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <ng-container *ngIf="trackerDetail?.project">
                            <div>
                                <label class="text-muted">Project Number</label>
                                <p class="fw-medium">{{trackerDetail?.project}}</p>
                            </div>
                            <div>
                                <label class="text-muted">Project Name</label>
                                <p class="fw-medium">{{trackerDetail?.project_details?.project_name || trackerDetail?.project}}</p>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="trackerDetail?.opportunity">
                            <div>
                                <label class="text-muted">Opportunity Number</label>
                                <p class="fw-medium">{{trackerDetail?.opportunity}}</p>
                            </div>
                            <div>
                                <label class="text-muted">Opportunity Name</label>
                                <p class="fw-medium">{{trackerDetail?.opportunity_details?.opportunity_name ||trackerDetail?.opportunity}}</p>
                            </div>
                        </ng-container>

                        <div>
                            <label class="text-muted">Portfolio</label>
                            <p class="fw-medium">{{trackerDetail?.portfolio_details?.[0]?.display_name || trackerDetail?.portfolio_details?.display_name}}</p>
                        </div>
                        <div>
                            <label class="text-muted">Sub Portfolio</label>
                            <p class="fw-medium">{{trackerDetail?.portfolio_details?.[1]?.display_name}}</p>
                        </div>
                        <div>
                            <label class="text-muted">Action CentralType</label>
                            <p class="fw-medium">{{trackerDetail?.action_tracker_type_detail?.display_name}}</p>
                        </div>
                        <!-- <div>
                            <label class="text-muted">Function</label>
                            <p class="fw-medium">{{trackerDetail?.action_function_detail?.display_name}}</p>
                        </div> -->
                        <div>
                            <label class="text-muted">Due Date</label>
                            <p class="fw-medium">{{trackerDetail?.due_date | date: 'd MMM y'}}</p>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <!-- <ng-template #delete>
        <div class="modal-dialog custom-width m-0">
            <div class="modal-content">
                <div class="modal-header">
                    <h2 mat-dialog-title class="mb-0">Confirmation</h2>
                </div>
                <div class="modal-body">
                    <p class=" fs-5 py-3 text-center primary m-0 fw-bold">Are you sure want to delete ?</p>
                </div>
                <div class="modal-footer border-0">
                    <div class="row">
                        <div class="col-md-6">
                            <button mat-button mat-dialog-close (click)="closeDialog()">No</button>
                        </div>
                        <div class="col-md-6">
                            <button mat-button mat-dialog-close (click)="deleteDialog()"
                                class="bg-primary text-white">Yes</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template> -->


    
<ng-template #delete>
    <div class="modal-dialog m-0">
        <div class="modal-content">
            <app-modal-header-common [title]="staticText?.modal_popup?.confirmation_title"> </app-modal-header-common>
            <div class="modal-body">
                <p class=" fs-18 py-3 text-center primary m-0 fw-bold">{{staticText?.modal_popup?.del_msg}}</p>
                <p>{{staticText?.modal_popup?.cant_retrieve}}</p>
            </div>
            <div class="modal-footer border-0">
                <div class="row">
                    <div class="col-md-6">
                        <button mat-button mat-dialog-close class="text-blue" (click)="closeDialog()">
                            {{staticText?.buttons?.cancel_btn}}</button>
                    </div>
                    <div class="col-md-6">
                        <button mat-button mat-dialog-close class="text-white bg-red"
                            (click)="deleteToeFile()">{{staticText?.buttons?.delete_btn}}</button>

                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>