<div class="container-fluid add-idea container-xxl">
    <div class="row">
        <div class="col-md-9 center-section  mt-4">
            <div class="row px-3">
                <div class="col-md-6">
                    <div class="pt-4">
                        <div class="cursor-pointer">
                            <app-bread-crumb></app-bread-crumb>
                            <h2 class="screen-name mb-0" (click)="navigateTo('/actiontracker')">
                                <i class="far fa-angle-left pr-2 "></i>{{staticText?.action_tracker.title}}
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="row">
                <form [formGroup]="actionTrackerForm" (ngSubmit)="submitActionTracker()" novalidate class="p-0">
                    <div class="scroll">
                        <div class="form-group required">
                            <div class="mb-3 d-flex">
                                <div class="bubble-top bg-1 mr-2"></div>
                                <div class="d-flex">
                                    <label for="benefits" class="labelstyle">{{staticText?.action_tracker.basic_details}}</label>
                                </div>
                                <hr>
                            </div>

                            <div class="d-flex form-group px-3 details-bg-1">
                                <div class="d-flex">
                                    <div class="required mb-0 mr-4">
                                        <mat-form-field class="title" appearance="outline">
                                            <mat-label>{{staticText?.action_tracker.title_field}}</mat-label>
                                            <input type="text" matInput formControlName="title"
                                                placeholder="Enter the Tracker Title..." required />
                                        </mat-form-field>
                                    </div>
                                    <div class="required mb-0  mr-4">
                                        <mat-form-field appearance="outline">
                                            <mat-label>{{staticText?.action_tracker.assignee}}</mat-label>
                                            <input matInput [matAutocomplete]="autoAssignee" type="text"
                                                formControlName="assignee">
                                            <mat-icon matSuffix class="muted">search</mat-icon>
                                            <mat-autocomplete #autoAssignee="matAutocomplete"
                                                [displayWith]="displayAssigneFn">
                                                <mat-option *ngFor="let option of filterAssignee | async"
                                                    [value]="option">
                                                    {{option.name}}
                                                </mat-option>
                                            </mat-autocomplete>
                                        </mat-form-field>
                                    </div>

                                    <div class=" mb-0 required ">
                                        <mat-form-field class="elementalign brief-description" appearance="outline">
                                            <mat-label>{{staticText?.action_tracker.brief_desc}}</mat-label>
                                            <textarea matInput id="solution_brief" formControlName="description"
                                                cdkTextareaAutosize placeholder="Enter the Description..."
                                                required></textarea>
                                        </mat-form-field>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="form-group required ">
                            <div class="mb-3 d-flex">
                                <div class="bubble-top bg-2 mr-2"></div>
                                <label for="benefits" class="labelstyle">{{staticText?.action_tracker.classifications}}</label>
                                <hr>
                            </div>
                            <div class="d-flex form-group px-3 details-bg-2">
                                <div class="d-flex">
                                    <div class="mr-4">
                                        <mat-form-field appearance="outline">
                                            <mat-label>{{staticText?.action_tracker.action_category}}</mat-label>
                                            <input matInput [matAutocomplete]="auto" type="text"
                                                formControlName="action_category">
                                            <mat-icon matSuffix class="muted">search</mat-icon>
                                            <mat-autocomplete #auto="matAutocomplete"
                                                [displayWith]="displayActionCatFn">
                                                <mat-option *ngFor="let option of filterActionTracker | async"
                                                    [value]="option">
                                                    {{option.display_name| titlecase}}
                                                </mat-option>
                                            </mat-autocomplete>
                                        </mat-form-field>
                                    </div>
                                    <!-- <div class="required mr-4">
                                        <mat-form-field appearance="outline">
                                            <mat-label>{{staticText?.opportunity.function}}</mat-label>
                                            <input matInput [matAutocomplete]="auto1" type="text"
                                                formControlName="action_function">
                                            <mat-icon matSuffix class="muted">search</mat-icon>
                                            <mat-autocomplete #auto1="matAutocomplete" [displayWith]="displayFunction">
                                                <mat-option *ngFor="let option of filterFunctionTracker | async"
                                                    [value]="option">
                                                    {{option.display_name| titlecase}}
                                                </mat-option>
                                            </mat-autocomplete>
                                        </mat-form-field>
                                    </div> -->
                                    <div class="required">
                                        <mat-form-field appearance="outline">
                                            <mat-label>{{staticText?.action_tracker.due_date}}</mat-label>
                                            <input matInput [matDatepicker]="picker" formControlName="due_date" autocomplete="off"
                                                (click)="picker.open()"> 
                                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker #picker></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group required">
                            <div class="mb-3 d-flex">
                                <div class="bubble-top bg-3  mr-2"></div>
                                <label for="benefits" class="labelstyle">{{staticText?.action_tracker.supporting_docs}}</label>
                                <hr>
                            </div>
                            <div class="d-flex form-group px-3">
                                <div class="col-md-3 p-0">
                                    <div class="upload mt-2 ">
                                        <div class="d-flex">
                                            <div class="w-100 cursor-pointer d-flex justify-content-center mb-2"
                                                (click)="fileInput1.click()">
                                                <small class="p-3 fw-bold m-0"> {{staticText?.common.upload_doc}}</small>
                                            </div>
                                        </div>
                                        <input multiple hidden (change)="onFileSelected($event)" #fileInput1 type="file"
                                            name="file" id="file" accept=".doc, .pdf, .docx, .ppt, .pptx">
                                        <small class="doc text-nowrap pt-2">
                                            {{staticText?.action_tracker.doc_maxsize}}</small>
                                    </div>
                                </div>

                                <div class="col-md-9 p-0">
                                    <div class="d-flex files">
                                        <p *ngIf="files.length == 0" class="pt-4 fw-bold add-doc cursor-pointer"
                                            (click)="fileInput1.click()"> <i class="fal fa-plus fw-bold"></i>{{staticText?.action_tracker.add_doc}}</p>

                                        <span *ngFor="let data of files" class="file d-flex">
                                            <div class="d-flex"
                                                *ngIf="data.name.includes('.doc') || data.name.includes('.docx') ">
                                                <img src="./assets/images/docs.png" height="40">
                                                <p class="w-100 cursor-pointer" style="font-size: 8pt;">
                                                    {{data.name}}</p>
                                                <img src="./assets/images/delete-icon-new.svg" height="40"
                                                    (click)="deleteFile(data)">
                                            </div>
                                            <div class="d-flex" *ngIf="data.name.includes('.pdf') ">
                                                <img src="./assets/images/pdf-icon-new.svg" height="40">
                                                <p class="w-100 cursor-pointer" style="font-size: 8pt;">
                                                    {{data.name}}</p>
                                                <img src="./assets/images/delete-icon-new.svg" height="40"
                                                    (click)="deleteFile(data)">
                                            </div>

                                            <div class="d-flex"
                                                *ngIf="data.name.includes('.pptx') || data.name.includes('.ppt')">
                                                <img src="./assets/images/powerpoint.png" height="40">
                                                <p class="w-100 cursor-pointer" style="font-size: 8pt;">
                                                    {{data.name}}</p>
                                                <img src="./assets/images/delete-icon-new.svg" height="40"
                                                    (click)="deleteFile(data)">
                                            </div>
                                        </span>
                                        <br />
                                    </div>
                                </div>
                            </div>
                            <div class="float-right">
                                <button mat-button (click)="navigateTo('/actiontracker')"
                                    class="my-3 px-4 p-0 border border-default me-3">
                                    <span class="m-0">
                                        {{staticText?.buttons.cancel_btn}}
                                    </span>
                                </button>
                                <button mat-button class="my-3 me-5 btn-bg text-white px-4 p-0">
                                    <span class="m-0">
                                        {{staticText?.action_tracker.add_tracker_btn}} 
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div class="col-md-3 mt-4">
            <div class="right-section">
                <span>
                    <div class="row m-0">
                        <div class="my-3 d-flex border-bottom p-0">
                            <div class="bubble-top bg-3  mr-2"></div>
                            <label for="benefits" class="labelstyle">{{staticText?.action_tracker.more_details}} </label>
                            <hr>
                        </div>
                    </div>
                </span>
                <div class="row mx-2">
                    <div class="d-flex align-items-center user-details">
                        <div class="mr-1">
                            <span class="avatar cursor-pointer">
                                {{getName(userDetails?.first_name)}} {{getName(userDetails?.last_name)}}
                            </span>
                        </div>
                        <div>
                            <div>
                                <p class="details">
                                    {{userDetails?.first_name}} {{userDetails?.last_name}}
                                </p>
                            </div>
                            <small class="text-dark text-nowrap">{{staticText?.action_tracker.creation_date}} {{currentDate | date: 'd MMM y'}}</small>
                        </div>
                    </div>
                    <div class="more-details">

                        <div class="d-flex align-items-center mb-3" *ngIf="vendorDetail?.vendor_details == true">
                            <div class="mr-1">
                                <span class="name-1 cursor-pointer">
                               <!--      {{getName(userDetails?.first_name)}} {{getName(userDetails?.last_name)}} -->
                                </span>
                            </div>
                            <div>
                                <div>
                                    <p class="details">
                                        {{items?.vendor_details?.primary_contact_details?.username}}
                                      <!--   {{userDetails?.first_name}} {{userDetails?.last_name}} -->
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex align-items-center mb-3" *ngIf="vendorDetail?.vendor_details == false">
                            <div class="mr-1">
                                <span class="name-1 cursor-pointer">
                                    {{getName(vendorDetail?.user_details)}}
                                </span>
                            </div>
                            <div>
                                <div>
                                    <p class="details">
                                        Delta {{vendorDetail?.user_details}}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <label class="text-dark">{{staticText?.filter.portfolio}}</label>
                            <p class="fw-medium">{{portfolio}}</p>
                        </div>
                        <div>
                            <label class="text-dark">{{staticText?.filter.sub_portfolio}}</label>
                            <p class="fw-medium">{{subportfolio}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>