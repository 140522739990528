import { Component, OnInit } from '@angular/core';
import { ProgramService } from 'src/app/shared/services/program.service';
import { GetProposalService } from 'src/app/shared/services/get-proposal.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup,FormArray,Validators } from '@angular/forms';
import { forkJoin, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActionTrackerService } from 'src/app/shared/services/action-tracker.service';
import { DataToCsvService } from 'src/app/shared/services/data-to-csv.service';
import { DatePipe } from '@angular/common';
import { ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActionCreateModalDialogComponent } from './action-create-modal/action-create-modal';


@Component({
  selector: 'app-list-tracker',
  templateUrl: './list-tracker.component.html',
  styleUrls: ['./list-tracker.component.scss'],
  providers:[DatePipe],
  encapsulation:ViewEncapsulation.Emulated
})
export class ListTrackerComponent implements OnInit {
  // OpportunityFieldList: any;
  // portList: any[] = [];
  // subPortfilteredOptions: any[] = [];
  // actionTrackerList: any;
  // actionTrackerListTotal: any;
  // filterForm: FormGroup | any;
  // filterOptionPortfolio1: Observable<any[]> | any;
  // filterOptionSubPortfolio1: Observable<any[]> | any;
  // endVal: number = 12;
  // startVal: number = 0;
  // itemsPerPage: number = 12;
  // searchText = '';
  // totalCount: any;
  // portFolioId: any;
  // subPortId: any;
  // scree_name = "actionTracker";
  // actionCategory: any;
  // //functionCategory: any;
  // action_category: any;
  // //action_function: any;
  // gdpList: any;
  // gdpId: any;

  actionTrackerForm: FormGroup | any;
  actionTrackerCategory:any;
  teamList:any;
  opportunityList:any;
  projectList:any;
  paradeList:any;
  curatedInsightList:any;
  updateIndex:any;
  showAddFlag:boolean = false;
  ownActions: any;
  otherActions: any;
  user: any;
 
  constructor(private proposalService: GetProposalService,
     private toastr: ToastrService,
    // private router: Router,
     private fb: FormBuilder,
    // private ps: ProgramService,
     private spinner: NgxSpinnerService,
    // private csvService: DataToCsvService,private datePipe:DatePipe,
     private actionTracker: ActionTrackerService,
     private dialog: MatDialog
  ) {
    // this.actionTrackerForm = this.fb.group({
    //   dynamicFields: this.fb.array([]),
    // });
   }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('permission') || '{}');
    this.getCategoryList();
    this.getUserList();
    this.getProjectList();
    this.getOpportunityList();
    this.getActionTracker();
    this.getParadeList();
    this.getCuratedInsightList();
  }

  createForm() {
    this.showAddFlag = true;
    const newField = this.fb.group({
      id:[''],
      title: ['', Validators.required],
      description:['',Validators.required],
      status:['',Validators.required],
      action_tracker_type:['',Validators.required],
      assignee:['',Validators.required],
      opportunity:[''],
      project:['']
    });
   this.dynamicFields.push(newField); 
  }

  get dynamicFields() {
    return this.actionTrackerForm.get('dynamicFields') as FormArray;
  }

  getActionTracker(){
    this.spinner.show();
    this.ownActions = [];
      this.otherActions = [];
    this.actionTracker.getActionTrackerListNew().subscribe((res: any) => {
      
      if(res?.length > 0){
        res.forEach((element: any) => {
          element.color = this.getGMapRandomColor(),
          element.last_updated_on_dt = new Date(element.last_updated_on)
        });
        
        res.forEach((element: any) => {
          if(element.created_by == this.user.id){
            this.ownActions.push(element);
          }else{
            this.otherActions.push(element);
          }
        });
        
        res?.forEach((ele:any)=>{
          // const newField = this.fb.group({
          //   id:[ele.id],
          //   title: [{value:ele.title,disabled:true}, Validators.required],
          //   description:[{value:ele.description,disabled:true},Validators.required],
          //   status:[{value:ele.status,disabled:true},Validators.required],
          //   action_tracker_type:[{value:ele.action_tracker_type,disabled:true},Validators.required],
          //   assignee:[{value:ele.assignee.username,disabled:true},Validators.required],
          //   opportunity:[{value:ele.opportunity,disabled:true}],
          //   project:[{value:ele.project,disabled:true}]
          // });
        // this.dynamicFields.push(newField); 
        });
        // this.createForm();
      }else{
        // this.createForm();
      }
      this.spinner.hide();
    });
    
  }

  createAction(x: any) {
    const dialog = this.dialog.open(ActionCreateModalDialogComponent, { width: '500px', 
    data: {teamList: this.teamList, actionTrackerCategory: this.actionTrackerCategory, 
      opportunityList: this.opportunityList, projectList: this.projectList, paradeList:this.paradeList, curatedInsightList:this.curatedInsightList, actionData: x }});
      dialog.afterClosed().subscribe(data => {
        if (!!data) {
          this.getActionTracker();
        }
    })
  }

  deleteAction(x:any,event:any){
    this.spinner.show();
    if(x?.id){
      this.actionTracker.deleteActionTrackerById(x?.id).subscribe((res:any)=>{
        this.getActionTracker();
      })
    }
    this.spinner.hide();
  }

  getGMapRandomColor() {
    return 'hsla(' + Math.floor(Math.random()*360) + ', 50%, 25%, 90%)';
  } 

  async getCategoryList(){
    return await this.actionTracker.getActionCategory().subscribe((res: any) => {
      this.actionTrackerCategory = res;
    });
  }

  async getUserList(){
    return await this.actionTracker.getUser().subscribe((res: any) => {
      this.teamList = res;
     });
  }
  async getOpportunityList(){
    return await this.actionTracker.getOpportunity().subscribe((res:any)=>{
      this.opportunityList = res;
    });
  }
  async getProjectList(){
    return await this.actionTracker.getProject().subscribe((res:any)=>{
      this.projectList = res;
    });
  }

  async getParadeList(){
    return await this.actionTracker.getParadeList().subscribe((res:any)=>{
      this.paradeList = res;
    });
  }

  async getCuratedInsightList(){
    return await this.actionTracker.getCuratedInsightList().subscribe((res:any)=>{
      this.curatedInsightList = res;
    });
  }
  editActionTracker(index:any){
    this.dynamicFields.controls[index].enable();
    this.updateIndex = index;
     document.getElementById('collapseOne'+index)?.classList.remove('collapse');
  }
  cancelEdit(index:any){
    this.dynamicFields.controls[index].disable();
    this.updateIndex = 'a';
  }
  removeActionTracker(field:any,index:any){
    this.spinner.show();
    if(field?.controls?.id?.value){
      this.actionTracker.deleteActionTrackerById(field?.controls?.id?.value).subscribe();
      this.dynamicFields.removeAt(index);
    }
    this.spinner.hide();
  }

  updateResponse(index:any){
    if(this.dynamicFields.controls[index].invalid){
      this.dynamicFields.controls[index].markAllAsTouched();
      // this.toastr.error("Please fill mandatory fields with valid input");
      return;
    }
    this.spinner.show();
    const data = this.formatdata(index);
     this.actionTracker.updateActionTracerById(data,this.dynamicFields.controls[index].value.id).subscribe((res:any)=>{
      this.spinner.hide();
     });
  }

  saveResponse(index:any){
    if(this.dynamicFields.controls[index].invalid){
      this.dynamicFields.controls[index].markAllAsTouched();
      // this.toastr.error("Please fill mandatory fields with valid input");
      return;
    }
    this.spinner.show();
    const data = this.formatdata(index);
    this.actionTracker.addNewActionTracker(data).subscribe((res:any)=>{
     this.spinner.hide();
     this.dynamicFields.clear();
     this.getActionTracker();
    });
    
  }

  formatdata(index:any){
    return {
      id: this.dynamicFields.controls[index].value.id,
      title: this.dynamicFields.controls[index].value.title,
      description :this.dynamicFields.controls[index].value.description,
      assignee_email: this.dynamicFields.controls[index].value.assignee,
      action_tracker_type: this.dynamicFields.controls[index].value.action_tracker_type,
      status: this.dynamicFields.controls[index].value.status,
      project: this.dynamicFields.controls[index].value.project,
      opportunity: this.dynamicFields.controls[index].value.opportunity
    }
  }

  toggleEvent(event:any){
    if(event.target.ariaExpanded === "true"){
       event.srcElement.classList.remove('rotate-minus-90deg');
       event.srcElement.classList.add('rotate-90deg');
    }
    if(event.target.ariaExpanded === "false"){
       event.srcElement.classList.remove('rotate-90deg');
       event.srcElement.classList.add('rotate-minus-90deg');
    } 
   }
 // createForm() {
    // this.filterForm = this.fb.group({
    //   portfolio: [''],
    //   subPortfolio: [''],
    // });
    // this.filterOptionPortfolio1 = this.filterForm.get('portfolio')!.valueChanges
    //   .pipe(
    //     startWith(''),
    //     map((value: any) => typeof value === 'string' ? value : value?.display_name),
    //     map((display_name: any) => display_name ? this.typeFilter(display_name) : this.portList.slice())
    //   );
  //}
  // private typeFilter(value: string): any {
  //   const filterValue = value.toLowerCase();
  //   return this.portList.filter((i: any) => i.display_name.toLowerCase().includes(filterValue));
  // }


  // getDataSource(isTotal:boolean=false) {
  //   this.spinner.show();
  //   let endVal:any = isTotal ? this.totalCount : this.endVal;
  //   this.actionTracker.getActionTrackerList(this.startVal, endVal, this.subPortId, this.searchText, this.portFolioId,this.action_category, '', this.gdpId).subscribe((res: any) => {
  //     this.spinner.hide();
  //     if (res) {
  //       this.totalCount = res.totalRecords;
  //       if(isTotal){
  //         this.actionTrackerListTotal = res.records;
  //         this.saveAsCSV();                   
  //       }else{
  //         this.actionTrackerList = res.records;
  //       }
  //     }
  //     else {
  //       this.toastr.error('Error', 'Internal Server Error');
  //     }
  //   }, (error: any) => { }, () => {
  //     this.spinner.hide();
  //   }
  //   )
  // }

  // getOpportunity() {
  //   this.ps.getOpportunityFormData('new_opportunity').subscribe((res: any) => {
  //     this.OpportunityFieldList = res.records;
  //     this.OpportunityFieldList.map((i: any) => {
  //       if (i.field_name == "opportunity_portfolio") {
  //         this.portList = i.field_values;
  //       }
  //     })
  //     this.createForm();
  //   }, error => {
  //     this.toastr.error('Error', error);
  //     throw error;
  //   })
  // }

  // selctedGDPValues(id: any) {
  //   this.gdpId = id;
  //   this.getDataSource();
  //   console.log("fdhdfcshg gdp", id);
  // }
  // SelectedActionValues(id: any) {
  //   this.action_category = id;
  //   this.getDataSource();
  // }
  // SelectedFunctionValues(id: any) {
  //   this.action_function = id;
  //   this.getDataSource();
  // }

  // selectedPortfolioValues(id: any) {
  //   console.log("sd");
    
  //   this.portFolioId = id;
  //   this.subPortId = "";
  //   // if (!this.portFolioId) {
  //   //   return;
  //   // }
  //   this.getDataSource();
  //   this.subPortfilteredOptions = [];
  // }

  // selectedSubportfolioValues(id: any) {
  //   this.subPortId = id;
  //   this.getDataSource();
  // }

  // changeSubPortfolio(e: any) {
  //   if (e.isUserInput) {
  //     this.subPortId = e.source.value.id;
  //     this.getDataSource();
  //   }
  // }
  // getGDP() {
  //   this.actionTracker.getVendorList().subscribe((res: any) => {
  //     if (res) {
  //       this.gdpList = res.records[0].field_values;
  //     }
  //   });
  // }

  // getActionCategory() {
  //   this.actionTracker.getActionCategoryList().subscribe((response: any) => {
  //     this.actionCategory = response;
  //   })
  // }

  // getFunctions() {
  //   this.actionTracker.getFunctionsList().subscribe((response: any) => {
  //     this.functionCategory = response;
  //   });
  // }

  // clearFilter() {
  //   this.searchText = '';
  //   this.portFolioId = 0;
  //   this.subPortId = 0;
  //   this.action_category = 0;
  //   //this.action_function = 0;
  //   this.gdpId = 0
  //   this.filterForm.patchValue({
  //     portfolio: '',
  //     subPortfolio: '',
  //   })
  //   this.getDataSource();
  // }

  // createTracker() {
  //   this.router.navigate(['actiontracker/add-new-tracker'])
  // }

  // showAllProposals() {
  // }


  // callRefereshParentData(e: any) {
  //   this.getDataSource();

  // }
  
  // saveAsCSV() {
  //   if(this.actionTrackerListTotal && this.actionTrackerListTotal.length > 0){
  //     const items:any = [];
  //     this.actionTrackerListTotal.forEach((item:any) => {
  //       let csvData = {
  //         "Tracker ID": item.unique_ticket_id,
  //         "Title": item.title,
  //         "Description": item.description,
  //         "Assignee": item.assignee.first_name +" "+ item.assignee.last_name,
  //         "Assignee Email": item.assignee.username,
  //         "Reported by": item.created_by.first_name +" "+ item.created_by.last_name,
  //         "Reported by Email": item.created_by.username,
  //         "Created on" :this.datePipe.transform(item?.created_on,'dd MMM yyyy'),
  //         "Due on" :this.datePipe.transform(item?.due_date,'dd MMM yyyy'),
  //         "Project Status":item.status?.action_complete_label
  //       }
  //       items.push(csvData); 
  //     });
  //     this.csvService.exportToCsv('Action Tracker_List.CSV', items);
  //   }else{
  //     this.toastr.error('No Records to download');
  //   }
 // }
}
