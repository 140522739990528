import { DatePipe } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ActionTrackerService } from "src/app/shared/services/action-tracker.service";

@Component({
    selector: 'action-create-modal',
    templateUrl: 'action-create-modal.html',
    styleUrls: ['action-create-modal.scss'],
    providers: [DatePipe],
  })
  export class ActionCreateModalDialogComponent {
    actionForm = new FormGroup({
      id: new FormControl(''),
      title: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      status: new FormControl('', [Validators.required]),
      assignee: new FormControl('', [Validators.required]),
      action_tracker_type:new FormControl('',[Validators.required]),
      // opportunity: new FormControl('', [Validators.required]),
      // project: new FormControl('', [Validators.required]),
      type_id: new FormControl(null)
    });

    teamList: any;
    actionTrackerCategory: any;
    opportunityList: any;
    projectList: any;
    paradeList: any;
    curatedInsightList: any;

    constructor(
      public dialogRef: MatDialogRef<ActionCreateModalDialogComponent>,
      private actionTracker: ActionTrackerService,
      private datePipe: DatePipe,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private router: Router,
      private toastrService:ToastrService,
      private spinner: NgxSpinnerService
    ) {}

    ngAfterViewInit(){
      this.teamList = this.data.teamList;
      this.actionTrackerCategory = this.data.actionTrackerCategory;
      this.opportunityList = this.data.opportunityList;
      this.projectList = this.data.projectList;
      this.paradeList = this.data.paradeList;
      this.curatedInsightList = this.data.curatedInsightList;
      if(this.data.actionData){
        this.actionForm.patchValue(this.data.actionData);
        // console.log(this.data.actionData.type_id);
        this.actionForm.get('type_id')?.setValue(this.data.actionData.type_id)
      }
    } 

    onCategorySelection(){
      if(this.actionForm.value.action_tracker_type == 1){
        this.actionForm.get('opportunity')?.setValidators(Validators.required);
        this.actionForm.get('project')?.clearValidators();
        this.actionForm.get('project')?.setValue('');
      }else{
        this.actionForm.get('project')?.setValidators(Validators.required);
        this.actionForm.get('opportunity')?.clearValidators();
        this.actionForm.get('opportunity')?.setValue('');
      }
    }
  

    createAction(){
      this.actionForm.markAllAsTouched();
      if (this.actionForm.invalid ) {
         return;
      }
     
      let postdata = this.actionForm.value;
      this.spinner.show();
      if(postdata.id){
        this.actionTracker.updateActionTracerById(postdata, postdata.id).subscribe((res:any)=>{
          this.spinner.hide();
          this.toastrService.success("Action updated successfully.")
          this.closeModal(true);
        });
      }else{
        this.actionTracker.addNewActionTracker(postdata).subscribe((res:any)=>{
          this.toastrService.success("Action created successfully.")
          this.spinner.hide();
          this.closeModal(true);
       });
      }
      
    }
  
    closeModal(bool: boolean = false): void {
      this.dialogRef.close(bool);
    }
  }