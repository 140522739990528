import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionTrackerService } from 'src/app/shared/services/action-tracker.service';
import { map, startWith } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { ViewEncapsulation } from '@angular/core';
import { FormValidatorService } from 'src/app/shared/services/form-validator/form-validator.service';
@Component({
  selector: 'app-add-tracker',
  templateUrl: './add-tracker.component.html',
  styleUrls: ['./add-tracker.component.scss'],
  providers: [DatePipe],
  encapsulation:ViewEncapsulation.Emulated
})
export class AddTrackerComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  files: any[] = [];
  fileList: any[] = [];
  actionTrackerForm: FormGroup | any;
  actionCategory: any;
  //functionCategory: any;
  assignee: any;
  filterActionTracker: any;
  //filterFunctionTracker: any;
  filterAssignee: any;
  public trackerFormData: any = new FormData();
  user: any;
  trackerDetailsName: any;
  userDetails: any;
  currentDate = new Date();
  portfolio: any;
  subportfolio: any;
  vendorDetail: any;
  items:any;
  @Output() refereshParentData = new EventEmitter<string>();

  constructor(private router: Router, private datepipe: DatePipe, public fb: FormBuilder, private activatedRoute: ActivatedRoute, private spinner: NgxSpinnerService, private toastrService: ToastrService, private actionTracker: ActionTrackerService,
    private _formValidator:FormValidatorService) { }

  ngOnInit(): void {
    this.getTrackerDetails();
    this.createForm();
    this.getActionCategory();
    //this.getFunctions();
    this.getAssignee();
    this.getvendorDetail();
  }

  getvendorDetail() {
    this.actionTracker.getvendordetails().subscribe((response: any) => {
      this.vendorDetail = response;
    });
  }

  getTrackerDetails() {
    if (localStorage.getItem("permission") != null && localStorage.getItem("permission") != undefined) {
      let logindata: any = localStorage.getItem("permission");
      if (logindata != null && logindata != 'undefined') {
        var loginDetails = JSON.parse(logindata);
        this.trackerDetailsName = loginDetails.username
        if (this.trackerDetailsName != '') {
          this.getUserDetails();
        }
      }
    }
  }


  getUserDetails() {
    const data = {
      username: this.trackerDetailsName
    }
    this.actionTracker.userDetails(data).subscribe((response: any) => {
      this.userDetails = response[0].user;
      this.portfolio = response[0].user.portfolio[0].display_name;
      this.subportfolio = response[0].user.sub_portfolio[0].display_name;
    },err=>this.spinner.hide())
  }

  createForm() {
    this.actionTrackerForm = this.fb.group({
      title: ['', [Validators.required, Validators.maxLength(100),] ],
      assignee: ['', [Validators.required,this._formValidator.autocompleteObjectValidator()] ],
      description: ['', [Validators.required] ],
      action_category: ['', [Validators.required,this._formValidator.autocompleteObjectValidator()] ],
      //action_function: ['', [Validators.required,this._formValidator.autocompleteObjectValidator()] ],
      due_date: ['', [Validators.required]],
      documents: [],
    });
  }

  submitActionTracker() {
    if (this.actionTrackerForm.valid) {
      this.spinner.show();
      this.trackerFormData.append('title', this.actionTrackerForm.value.title)
      this.trackerFormData.append('description', this.actionTrackerForm.value.description)
      this.trackerFormData.append('assignee', this.actionTrackerForm.value.assignee.mail)
      this.trackerFormData.append('action_category', this.actionTrackerForm.value.action_category.id)
      //this.trackerFormData.append('action_function', this.actionTrackerForm.value.action_function.id)
      this.trackerFormData.append('due_date', this.datepipe.transform(this.actionTrackerForm.value.due_date, 'yyyy-MM-dd'))
      for (var i = 0; i < this.files.length; i++) {
        this.trackerFormData.append('documents', this.files[i]);
      }
      this.actionTracker.submitForm(this.trackerFormData).subscribe((res: any) => {

        if (res) {
          this.toastrService.success("Action  Created Succesfully");
          this.router.navigateByUrl('/actiontracker');
          this.actionTrackerForm.reset();
          this.spinner.hide();
        }
        else {
          this.toastrService.warning("Please Enter mandatory Fields");
          this.spinner.hide();
        }
      });
    }
    else {
      return
    }

  }

  navigateTo(link: string) {
    this.router.navigate([link])
  }


  getActionCategory() {
    this.actionTracker.getActionCategoryList().subscribe((response: any) => {
      this.actionCategory = response;
      this.filterActionTracker = this.actionTrackerForm.get('action_category')!.valueChanges
        .pipe(
          startWith(''),
          map((value: any) => typeof value === 'string' ? value : value?.display_name),
          map((name: any) => name ? this._filterActionTracker(name) : this.actionCategory.slice())
        );
    });
  }

  getAssignee() {
    this.actionTracker.getAssigneeList().subscribe((response: any) => {
      this.assignee = response['records'].find((i: any) => i.field_name == "opportunity_team").field_values;      
      this.filterAssignee = this.actionTrackerForm.get('assignee')!.valueChanges
        .pipe(
          startWith(''),
          map((value: any) => typeof value === 'string' ? value : value?.display_name),
          map((name: any) => name ? this._filterAssignee(name) : this.assignee.slice())
        );
    });
  }
  // getFunctions() {
  //   this.actionTracker.getFunctionsList().subscribe((response: any) => {
  //     this.functionCategory = response;
  //     this.filterFunctionTracker = this.actionTrackerForm.get('action_function')!.valueChanges
  //       .pipe(
  //         startWith(''),
  //         map((value: any) => typeof value === 'string' ? value : value?.display_name),
  //         map((name: any) => name ? this._filterFunctionTracker(name) : this.functionCategory.slice())
  //       );
  //   });
  // }
  private _filterActionTracker(value: any): string[] {
    const filterValue = value.toLowerCase();
    return this.actionCategory.filter((data: any) => data?.display_name?.toLowerCase().includes(filterValue));
  }
  // private _filterFunctionTracker(value: any): string[] {
  //   const filterValue = value.toLowerCase();
  //   return this.functionCategory.filter((e: any) => e?.display_name?.toLowerCase().includes(filterValue));
  // }
  private _filterAssignee(value: any): string[] {
    const filterValue = value.toLowerCase();
    return this.assignee.filter((e: any) => e?.first_name?.toLowerCase().includes(filterValue));
  }

  displayFn(data: any) {
    return data && data.display_name ? data.display_name : '';
  }
  displayActionCatFn(data: any) {
    return data && data.display_name ? data.display_name : '';
  }
  displayAssigneFn(data: any) {
    return data && data.name ? data.name : '';
  }



  onFileSelected(e: any) {
    for (var i = 0; i < e.target.files.length; i++) {
      this.files.push(e.target.files[i]);
      this.fileList.push(e.target.files[i].name,);
    }
  }

  deleteFile(data: any) {
    this.files = this.files.filter(item => item.name !== data.name);
  }


  getName(name: any) {

    return name?.split(' ').slice(0, 2).map((n: any) => n[0],).join('');

  }



}
