<div class="row">
    <div class="col-sm-6 trn-trc">
        <h5>Action Central </h5>
    </div>
    <div class="col-sm-6 trn-trc-1">
        <button mat-flat-button mat-button class="bg-primary text-white" color="primary" aria-label="add" (click)="createAction(null)">
            <mat-icon>add</mat-icon> Add Action
        </button>
    </div>
</div>

<div class="mt-4 action-tracker">
    <h6>Actions created by you </h6>
    <div class="flex-container-1">
        <div *ngIf="ownActions && ownActions.length <= 0 " class="trn-no">
            You haven't created any actions.
        </div>

        <app-loading style="width: 100%; min-height: 200px;" *ngIf="!ownActions"></app-loading>

        <div class="flex-item p-1" *ngFor="let x of ownActions;">
            <div class="trn-x" [ngStyle]="{'background': x.color}" (click)="createAction(x)">
                <div class="d-flex justify-content-between">
                    <h6 class="mb-0 trn-nm">{{x.title}}</h6>
                </div>

                <p class="mb-0 trn-ph">
                    {{x.description}}
                </p>


                <div class="st-col" [ngStyle]="{'background': x.status == 'Open' ? '#ee8911' : 'darkgreen'}">
                    {{x.status}}</div>

                <div class="row tmg">
                    <div class="col-sm-12 ta">
                        <p class="mb-0 tap">Updated {{x.last_updated_on_dt | timeago}}</p>
                    </div>
                </div>
                <button class="action-vert" color="warn" (click)="deleteAction(x); $event.stopPropagation()"
                    mat-icon-button>
                    <mat-icon style="color: red;">delete</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>
<div class="mt-4">
    <h6>Actions you are collaborating on</h6>
    <div class="flex-container-1">
        <app-loading style="width: 100%; min-height: 200px;" *ngIf="!otherActions"></app-loading>

        <div *ngIf="otherActions && otherActions.length <= 0 " class="trn-no">
            There are currently no available actions for collaboration.
        </div>
        <div class="flex-item p-1" *ngFor="let x of otherActions;">
            <div class="trn-x" [ngStyle]="{'background': x.color}" (click)="createAction(x)">
                <div class="d-flex justify-content-between">
                    <h6 class="mb-0 trn-nm">{{x.title}}</h6>
                </div>
                <p class="mb-0 trn-ph">
                    {{x.description}}
                </p>

                <div class="st-col" [ngStyle]="{'background': x.status == 'Open' ? '#ee8911' : 'darkgreen'}">
                    {{x.status}}</div>

                <div class="row tmg">
                    <div class="col-sm-12 ta">
                        <p class="mb-0 tap">{{x.last_updated_on_dt | timeago}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>